import React from "react";
import styled from 'styled-components';
import { Link } from "react-router-dom";

const StyledNavItem = styled.li`
  display: block;
  margin: 0 16px 0 0;
  text-decoration: none;

  a {
    font-weight: bold;
    text-decoration: none;
    font-size: 18px;
    padding: 8px;
    text-transform: uppercase;
    border-radius: 10px;
    transition-duration: 400ms;
    color: #333;
  }
  a:visited {
    color: #333;
  }
  a:hover {
    color: #999;
    /* color: #CEFF00; */
    /* background-color: #333; */
  }
`;

export default function NavItem(props) {
  const { to, label } = props;
  return (
    <StyledNavItem>
      <Link to={to}>
        {label}
      </Link>
    </StyledNavItem>
  );
}