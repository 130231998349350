import React from 'react';
import styled from 'styled-components';
import HeaderIcon from './HeaderIcon';

const StyledHeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledHeaderText = styled.h1`
  font-size: 2.25rem;
  margin-left: 6px;
  color: #000;
  text-transform: uppercase;
`;

export default function PageHeader(props) {
  const { title, iconType } = props;
  return (
    <StyledHeaderContainer>
      <HeaderIcon iconType={iconType} />
      <StyledHeaderText>{title}</StyledHeaderText>
    </StyledHeaderContainer>
  );
} 