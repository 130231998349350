const navList = [
  {
    to: '/',
    label: 'Home',
  },
  {
    to: '/register',
    label: 'Register',
  },
  {
    to: '/tips',
    label: 'Tips',
  },
  {
    to: '/new',
    label: 'Whats New',
  },
]

export default navList;