import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";

const StyledLinkButton = styled(Link)`
  display: flex;
  border-radius: 100px;
  font-size: 1.25rem;
  line-height: 1rem;
  font-weight: bold;
  width: 200px;
  height: 55px;
  margin: 0 100px;
  font-style: italic;
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;

  &.green {
    background-color: #1D6408;
  }
  &.blue {
    background-color: #1C0DF1;
  }

  &.square {
    width: 250px;
    border-radius: 4px;
  }
`;

function LinkButton(props) {
  const { link, label, color, variant } = props;

  return (
    <StyledLinkButton
      to={link}
      className={`${color} ${variant}`}
    >
      {label}
    </StyledLinkButton>
  );
}

export default LinkButton;