import React from "react";
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import NavConatiner from "../components/Nav";
import registerImage from '../assets/registerbg.jpg';
import tipsImage from '../assets/tipsbg.jpeg';
import newImage from '../assets/newbg.jpeg';

const StyledLayout = styled.div`
  min-height: 100dvh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  &.register {
    background-image: url(${registerImage});
  }
  &.tips {
    background-image: url(${tipsImage});
  }
  &.new {
    background-image: url(${newImage});
  }
`;

const StyledLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #FFFFFF90;
  min-height: 100vh;
  padding: 0 40px;
`;

const StyledContentContainer = styled.div`
  width: 100%;
  max-width: 1400px;
`;

function Layout(props) {
  const { children } = props;
  const location = useLocation();

  function backgroundClass(path) {
    if (path === '/register') {
      return 'register';
    } else if (path === '/tips') {
      return 'tips';
    } else if (path === '/new') {
      return 'new';
    } else {
      return '';
    }
  };

  return (
    <StyledLayout className={backgroundClass(location.pathname)}>
      <StyledLayoutContainer>
        <NavConatiner />
        <StyledContentContainer>
          {children}
        </StyledContentContainer>
      </StyledLayoutContainer>
    </StyledLayout>
  );
}

export default Layout;