import React from 'react';
import Grid from '@mui/material/Grid';
import PageHeader from '../components/PageHeader';
import Layout from './Layout';

export default function New() {
  return (
    <Layout>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PageHeader title={`What's New`} iconType={'new'} />
        </Grid>
      </Grid>
    </Layout>
  );
}
