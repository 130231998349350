import React from 'react';
import styled from 'styled-components';
import HomeTitle from '../components/HomeTitle';
import LinkButton from '../components/LinkButton';
import FitnessWellnessLogo from '../assets/FWCSP23 - BLK.png'
import Layout from './Layout';

const StyledHomeContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 300px;
  flex-grow: 1;
`;

const StyledButtonRow = styled.div`
  height: 300px;
  display: flex;
  justify-content: center;
  padding: 15px;
`;

const ImgContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledImage = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 800px;
  height: 300px;
`;

export default function Home() {
  return (
    <Layout>
      <StyledHomeContainer>
        <HomeTitle />
        <StyledButtonContainer>
          <StyledButtonRow>
            <LinkButton
              link={'/new'}
              label={`What's New`}
              color={'green'}
            />
            <LinkButton
              link={'/tips'}
              label={`tips`}
              color={'green'}
            />
          </StyledButtonRow>
          <StyledButtonRow>
            <LinkButton
              link={'/register'}
              label={`Register`}
              variant={'square'}
              color={'blue'}
            />
          </StyledButtonRow>
        </StyledButtonContainer>
        <ImgContainer>
          <StyledImage src={FitnessWellnessLogo} />
        </ImgContainer>
      </StyledHomeContainer>
    </Layout>
  );
}
