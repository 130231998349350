import React, { useState } from "react";
import styled from "styled-components";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Paper from '../Surface/Paper'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import ConfirmRegistration from "./ConfirmRegistration";
// import UserInfoHook from "../../hooks/UserInfo";

const StyledFormControlInput = styled(FormControl)`
  width: 100%;

  && .MuiTextField-root {
    width: calc(516px);
  }
`;

const StyledFormControlSelect = styled(FormControl)`
  &.MuiFormControl-root {
    margin: 8px;
    width: calc(516px);
  }
`;

const StyledButton = styled(Button)`
  &.MuiButtonBase-root{
    border-color: #00A1DF;
    color: #00A1DF;
  }
  &.MuiButtonBase-root:hover {
    border-color: #000;
    color: #000;
  }
  &&.MuiButtonBase-root {
    margin-top: .5rem;
    margin-left: .5rem;
  }
`;

export default function RegisterForm(props) {
  const { step, setStep } = props;
  const [category, setCategory] = useState();
  // const { isLoaded, userInfo } = UserInfoHook();

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  const handleSubmit = () => {
    if (step === 1) {
      setStep(2);
    } else {
      setStep(1);
    }
  }

  if (step === 1) {
    return (
      <Box
        component="form"
        sx={{
          width: 566,
          '& .MuiTextField-root': { m: 1, width: '250px' },
        }}
        noValidate
        autoComplete="off"
      >
        <Paper>

          <TextField
            required
            id="first-name"
            label="First Name"
            value={'yesssfdasd'}
          />
          <TextField
            required
            id="last-name"
            label="Last Name"
            defaultValue=""
          />
          <TextField
            required
            id="email"
            label="Email"
            defaultValue=""
          />

          <StyledFormControlSelect fullWidth>
            <InputLabel id="category-select-label">Category</InputLabel>
            <Select
              labelId="category-select-label"
              id="category-select"
              value={category}
              label="Category"
              onChange={handleChange}
            >
              <MenuItem value={'Healthy Eating'}>Healthy Eating</MenuItem>
              <MenuItem value={'Fitness'}>Fitness</MenuItem>
              <MenuItem value={'Gain Muscle'}>Gain Muscle</MenuItem>
              <MenuItem value={'Mindful'}>Mindful</MenuItem>
              <MenuItem value={'Weight Loss'}>Weight Loss</MenuItem>
              <MenuItem value={'All Inclusive'}>All Inclusive</MenuItem>
            </Select>
          </StyledFormControlSelect>
          <StyledFormControlInput>
            <TextField
              id="goal"
              label="Goal"
              multiline
              rows={4}
              defaultValue=""
              fullWidth
            />
          </StyledFormControlInput>
          <StyledButton variant="outlined" onClick={handleSubmit}>Submit</StyledButton>
        </Paper>
      </Box>
    )
  } else {
    return (
      <ConfirmRegistration />
    )
  }

}