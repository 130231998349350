import React from "react";
import styled from "styled-components";
import bottleSodaOutline from '../assets/bottle-soda-classic-outline.svg';
import commentTextOutline from '../assets/comment-text-outline.svg';
import bowlMixOutline from '../assets/bowl-mix-outline.svg';
import apps from '../assets/apps.svg';
import sealVariant from '../assets/seal-variant.svg';
import commentQuestionOutline from '../assets/comment-question-outline.svg';

const StyledTipsBlock = styled.div`
  display: flex;
`;

const StyledIconBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  /* background-color: red; */
  /* border: 1px solid red; */
`;

const TextBlock = styled.div`
  background-color: #F1C7F0;
  border: 1px solid #707070;
  border-radius: 4px;
  padding: 16px;
  width: 800px;
  margin-left: 8px;
`;

const StyledText = styled.p`
  font-size: 20px;
  font-family: Roboto;
  font-style: italic;
  margin-block-start: 0em;
  margin-block-end: 0em;
`;

const StyledIconImg = styled.img`
  max-width: 100px;
  max-height: 100px;
`;

const renderIconType = (icon) => {
  if (icon === 'bottle') {
    return <StyledIconImg src={bottleSodaOutline} alt="Bottle Water" />;
  } else if (icon === 'message') {
    return <StyledIconImg src={commentTextOutline} alt="Message" />;
  } else if (icon === 'bowl') {
    return <StyledIconImg src={bowlMixOutline} alt="Bowl Mix" />;
  } else if (icon === 'apps') {
    return <StyledIconImg src={apps} alt="Apps" />;
  } else if (icon === 'seal') {
    return <StyledIconImg src={sealVariant} alt="Surprises" />;
  } else if (icon === 'question') {
    return <StyledIconImg src={commentQuestionOutline} alt="Questions" />;
  } else {
    return '';
  }
}

export default function TipsBlock(props) {
  const { icon, children } = props;

  return (
    <StyledTipsBlock>
      <StyledIconBlock>
        {renderIconType(icon)}
      </StyledIconBlock>
      <TextBlock>
        <StyledText>
          {children}
        </StyledText>
      </TextBlock>
    </StyledTipsBlock>
  );
}