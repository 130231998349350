import React from 'react';
import styled from 'styled-components';
import Icon from '@mui/material/Icon';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import DesignServicesRoundedIcon from '@mui/icons-material/DesignServicesRounded';
import EmojiPeopleOutlinedIcon from '@mui/icons-material/EmojiPeopleOutlined';

const StyledIcon = styled(Icon)`
  margin-top: 4px;
  .MuiSvgIcon-root {
    color: #000;
    font-size: 3rem;
    height: 3rem;
    width: 3rem;
  }

  &.MuiIcon-root {
    height: 3rem;
    width: 3rem;
  }
`;

const selectIcon = (iconType) => {
  switch (iconType) {
    case 'register':
      return <PersonAddIcon />
    case 'new':
      return <FiberNewIcon />
    case 'tips':
      return <DesignServicesRoundedIcon />
    case 'welcome':
      return <EmojiPeopleOutlinedIcon />
    default:
      return <PersonAddIcon />
  }
}

export default function HeaderIcon(props) {
  const { iconType } = props;
  return (
    <StyledIcon fontSize={'inherit'}>
      {selectIcon(iconType)}
    </StyledIcon>
  );
}