import React from 'react';
import styled from 'styled-components';

const StyledTitle = styled.header`
  padding: 0px;
`;

const StyledH1 = styled.h1`
 margin-block-end: 0em;
`;

const StyledBlue = styled.span`
  color: blue;
`;

const StyledGreen = styled.span`
  color: green;
`;

const StyledBlack = styled.span`
  color: black;
`;

const StyledSubText = styled.p`
  margin-left: 125px;
  color: black;
  margin-block-start: 0em;
  margin-block-end: 1em;
`;

function HomeTitle() {
  return (
    <StyledTitle>
      <StyledH1>
        <StyledBlue>
          FITNESS
        </StyledBlue>
        <StyledBlack>
          +
        </StyledBlack>
        <StyledGreen>
          WELLNESS
        </StyledGreen>
        {' '}
        <StyledBlack>
          CHALLENGE
        </StyledBlack>
      </StyledH1>
      <StyledSubText>
        is the most positive, uplifting, re-invigorating, and supportive wellness challenge of all!
      </StyledSubText>
    </StyledTitle>
  );
}

export default HomeTitle;