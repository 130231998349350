const CLIENT_ID = process.env.CLIENT_ID || 'nike.techops.fitness-wellness-challenge';
// const ISSUER = process.env.ISSUER || 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7/';
const ISSUER = process.env.ISSUER || 'https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7/';
const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
const BASENAME = '/';
// BASENAME includes trailing slash
const REDIRECT_URI = `${window.location.origin}${BASENAME}login/callback`;

const oktaConfig = {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  },
  // resourceServer: {
  //   messagesUrl: 'http://localhost:8000/api/messages',
  // },
  app: {
    basename: BASENAME,
  },
};

export default oktaConfig;