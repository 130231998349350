import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import PageHeader from '../components/PageHeader';
import RegisterForm from '../components/RegisterForm/RegisterForm';
import Layout from './Layout';

export default function Register() {
  const [step, setStep] = useState(1);
  const headerText = (step === 1 ? 'Register' : 'Welcome');
  const iconType = (step === 1 ? 'register' : 'welcome');

  return (
    <Layout>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PageHeader title={headerText} iconType={iconType} />
        </Grid>
        <Grid item xs={12}>
          <RegisterForm step={step} setStep={setStep} />
        </Grid>
      </Grid>
    </Layout>
  );
}