import React from 'react';
import Grid from '@mui/material/Grid';
import PageHeader from '../components/PageHeader';
import TipsBlock from '../components/TipsBlock';
import Layout from './Layout';
export default function Tips() {
  return (
    <Layout>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PageHeader title={`Tips`} iconType={'tips'} />
        </Grid>
        <Grid item xs={12}>
          <TipsBlock icon={'bottle'}>
            Have your water bottle with you every day.
            Did you know that we mistake being hungry by being dehydrated?
            We need to drink half of our weight in ounces each day to rehydrate our bodies.
          </TipsBlock>
        </Grid>
        <Grid item xs={12}>
          <TipsBlock icon={'message'}>
            Sharing nutrition tips and information each week which will
            provide the foundation for healthy eating habits that will make lifelong
            changes. By following this advice, you will not gain your weight back after
            this challenge. We promote a healthy lifestyle not a diet..
          </TipsBlock>
        </Grid>
        <Grid item xs={12}>
          <TipsBlock icon={'bowl'}>
            ESwitch your dinner plates for salad plates.
            This will ensure you eat the correct portions for each meal you have at home.
          </TipsBlock>
        </Grid>
        <Grid item xs={12}>
          <TipsBlock icon={'apps'}>
            Download a health tracking app or you can buy a fitness journal to see for
            yourself how your efforts pay off day by day and week by week.
          </TipsBlock>
        </Grid>
        <Grid item xs={12}>
          <TipsBlock icon={'seal'}>
            We will do  surprise mini challenges. Stay put in slack to know how you can win prizes.
          </TipsBlock>
        </Grid>
        <Grid item xs={12}>
          <TipsBlock icon={'question'}>
            Share your comments, questions, victories, recipes, fitness routine, struggles,
            and share all you are going through. Through Slack, we are building a
            community of support. We are all in this together.
            Please follow us in Workvivo Nike Sports Centers space
            Slack channel: nike-fitness-wellness-challenge-Spring 2023
          </TipsBlock>
        </Grid>
      </Grid>
    </Layout>
  );
}