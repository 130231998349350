import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import NavItem from "./NavItem";
import navList from "./navList";
import { useOktaAuth } from '@okta/okta-react';
import fetchEnv from "../../fetchEnv/fetchEnv";

const StyledNavContainer = styled.nav`
  width: 100%;
  max-width: 1400px;
  display: flex;
  justify-content: space-between;
`;

const StyledNav = styled.ul`
  padding: 0;
  display: flex;
  margin-left: -8px;
`;

const StyledUser = styled.div`

`;

const generateNavList = (navList) => {
  return navList.map((item, index) => <NavItem to={item.to} label={item.label} key={index} />)
}

export default function NavConatiner() {

  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  // const [appSettings, setAppSettings] = useState({});
  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
      }).catch((err) => {
        console.error(err);
      });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  useEffect(() => {
    async function fetchData() {
      const response = await fetchEnv();

      // setAppSettings(response);
      console.log('response', response);
    }

    fetchData();
  }, [])

  return (
    <StyledNavContainer>
      <StyledNav>
        {generateNavList(navList)}
      </StyledNav>
      <StyledUser>
        {userInfo?.preferred_username}
      </StyledUser>
    </StyledNavContainer>
  );
}