import React from "react";
import styled from "styled-components";
import Button from '@mui/material/Button';

const StyledButton = styled(Button)`
  &.MuiButtonBase-root{
    border: 1px solid;
    border-color: #00A1DF;
    color: #00A1DF;
  }
  &.MuiButtonBase-root:hover {
    border-color: #000;
    color: #000;
  }
  &&.MuiButtonBase-root {
    margin-top: .5rem;
    margin-left: auto;
  }
`;

export default function SubmitButton(props) {
  const { onClick } = props;
  return (
    <StyledButton onClick={onClick}>
      Continue
    </StyledButton>
  );
}
