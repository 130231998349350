const fetchEnv = async function (context, req) {
  console.log('JavaScript HTTP trigger function processed a request.');

  const testKey = process.env.REACT_APP_TEST_KEY;

  console.log("REACT_APP_TEST_KEY::::::" + testKey);

  const response = {
    status: 200,
    body: { testKey }
  };

  return response;
};

export default fetchEnv;