import React from "react";
import styled from 'styled-components';
import SubmitButton from "./SubmitButton";
import Paper from "../Surface/Paper";

const StyledConfirm = styled.h1`
  color: #000;
  margin-left: 1rem;
`
const StyledConfirmContainer = styled.div`
  max-width: 556px;
  margin: auto;
`;

const ConfirmText = styled.p`
  margin-left: 1rem;
`;

const StiledCTA = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default function ConfirmRegistration(props) {
  const { onClick } = props;

  return (
    <StyledConfirmContainer>
      <Paper>
        <StyledConfirm>
          Thank You For Registering
        </StyledConfirm>
        <ConfirmText>
          Participant number #42
        </ConfirmText>
        <StiledCTA>
          <SubmitButton onClick={onClick} />
        </StiledCTA>
      </Paper>
    </StyledConfirmContainer>
  );
}