import React from 'react';
import './App.css';
import { Route, useHistory, Switch } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import CorsErrorModal from './okta/CorsErrorModal';
import AuthRequiredModal from './okta/AuthRequiredModal'
import Home from './pages/Home';
import WhatsNew from './pages/WhatsNew'
import Tips from './pages/Tips';
import Register from './pages/Register';
import config from './config';

const oktaAuth = new OktaAuth(config.oidc);

export default function App() {
  const [corsErrorModalOpen, setCorsErrorModalOpen] = React.useState(false);
  const [authRequiredModalOpen, setAuthRequiredModalOpen] = React.useState(false);

  const history = useHistory(); // example from react-router

  const triggerLogin = async () => {
    await oktaAuth.signInWithRedirect();
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    console.log('relative url', originalUri || '/', window.location.origin);
  };

  const customAuthHandler = async () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      // App initialization stage
      await triggerLogin();
    } else {
      // Ask the user to trigger the login process during token autoRenew process
      setAuthRequiredModalOpen(true);
    }
  };

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      {/* <Navbar {...{ setCorsErrorModalOpen }} /> */}
      {/* <NavConatiner /> */}
      <CorsErrorModal {...{ corsErrorModalOpen, setCorsErrorModalOpen }} />
      <AuthRequiredModal {...{ authRequiredModalOpen, setAuthRequiredModalOpen, triggerLogin }} />
      <Switch>
        <Route path="/login/callback" exact component={LoginCallback} />
        <SecureRoute path="/" exact component={Home} />
        <SecureRoute path="/register" component={Register} />
        <SecureRoute path="/new" component={WhatsNew} />
        <SecureRoute path="/tips" component={Tips} />
      </Switch>
    </Security >
  );
}