import React from 'react';
import styled from 'styled-components';
import MUIPaper from '@mui/material/Paper';

const StyledPaper = styled(MUIPaper)`
  padding: 1rem;
`;

export default function Paper(props) {
  const { children } = props;
  return (
    <StyledPaper>
      {children}
    </StyledPaper>
  )
}